import 'picturefill';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js';
import 'lazysizes';

// Configure lazySizes.
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;
window.lazySizesConfig.customMedia = {
  '--portrait'  : '(orientation: portrait) and (max-width: 767px)',
  '--landscape' : '(min-width: 768px), (orientation: landscape)',
  '--md-and-up' : '(min-width: 769px)',
  '--sm'        : '(max-width: 768px)'
};
